import React from 'react';

function Team() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Our passionate team</h2>
            <p className="text-xl text-gray-400">Elasticfleet offers cloud IoT solutions to help companies of all sizes to reach their safety and productivity goals in their business processes or customer experiences.</p>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>

            {/* 1st member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/sagar-4.png')} width="120" height="120" alt="Team member 01" />
                <h4 className="text-xl font-medium mb-1">Sagar Chitturi</h4>
                <div className="text-gray-500 mb-1">CEO & Co-founder</div>
                {/* <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@laraamprecht</a> */}
              </div>
            </div>

            {/* 2nd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/team2.png')} width="120" height="120" alt="Team member 02" />
                <h4 className="text-xl font-medium mb-1">Kranthi Davuluri</h4>
                <div className="text-gray-500 mb-1">CTO & Co-founder</div>
                {/* <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@marie_moon</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;